<template>
  <div class="search">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="onLoad"
    >
      <!-- <van-sticky>
        <search-top @changeOrder="changeOrder" :order="order"></search-top>
      </van-sticky> -->
      <product-list
        :productList="productList"
        :isPlaceholder="isPlaceholder"
        ref="search"
      ></product-list>
    </van-list>
  </div>
</template>
<script>
import ProductList from "@components/common/ProductList";
// import SearchTop from './components/SearchTop'
// import * as methodType from '@/plugins/environment/action-type.js'
import Vue from "vue";
import { Sticky, List } from "vant";

Vue.use(List).use(Sticky);
export default {
  name: "Search",
  components: {
    ProductList,
    // SearchTop
  },
  data() {
    return {
      productList: [],
      sreachValues: "", // 搜索关键词
      pageIndex: 1,
      order: "", // 排序
      // isNoData: false,
      // isfirst: true,
      loading: true,
      finished: false,
      isPlaceholder: false,
      isCouponProduct: false,
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    },
  },
  mounted() {
    const { SreachValues, Order, title, IsCouponProduct } = this.$route.query;
    this.sreachValues = SreachValues;
    this.order = Order;
    this.isCouponProduct = IsCouponProduct;
    this.getSearchProductByPage();
    title && (document.title = title);
  },
  methods: {
    // 获取搜索页数据
    getSearchProductByPage() {
      this.$commonMethod.showLoading();
      let param = {};
      if (this.order !== 1 && this.order !== 2) {
        param = {
          SreachValues: this.sreachValues,
          PageIndex: this.pageIndex,
          IsCouponProduct: this.isCouponProduct,
        };
      } else {
        param = {
          SreachValues: this.sreachValues,
          Order: this.order,
          PageIndex: this.pageIndex,
          IsCouponProduct: this.isCouponProduct,
        };
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          const data = res.data;
          this.productList = data;
          // this.isfirst = false;
          if (
            this.productList === null ||
            this.productList === "" ||
            this.productList.length === 0
          ) {
            this.isPlaceholder = true;
          } else {
            this.isPlaceholder = false;
          }
          this.loading = false;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.isfirst = false;
          this.finished = true;
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction("showDialog", {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 筛选
    changeOrder(type) {
      this.$refs.search.scrollTop = 0;
      this.order = type;
      this.pageIndex = 1;
      this.loading = true;
      this.finished = false;
      this.getSearchProductByPage();
    },
    // 加载更多
    onLoad() {
      let param = {};
      if (this.order !== 1 && this.order !== 2) {
        param = {
          SreachValues: this.sreachValues,
          PageIndex: this.pageIndex + 1,
          IsCouponProduct: this.isCouponProduct,
        };
      } else {
        param = {
          SreachValues: this.sreachValues,
          Order: this.order,
          PageIndex: this.pageIndex + 1,
          IsCouponProduct: this.isCouponProduct,
        };
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          const data = res.data;
          if (data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.productList = this.productList.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction("showDialog", {
            msg: error.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  width: 100%;
  background-color: #f6f6f6;
  ::v-deep .van-list__finished-text {
    font-size: 14px;
    padding: 14px 0;
  }
  ::v-deep .van-loading__text {
    font-size: 14px;
    padding: 5px 0;
  }
}
</style>
